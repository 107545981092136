import InputMask from 'react-input-mask';
import React from 'react';
import classnames from 'classnames';
import styles from './creditCard.module.scss';
import { SchemaParser } from '../../services';
import { store } from '../../../models';

const creditCard = ({
  props: {
    header,
    saveCheckbox,
    label,
    handleChange,
    paymentMethod,
    existingCards,
    handleAddNewCard,
    newCardForm,
    profileSettings,
    makeDefaultCheckBox,
    discard,
    inputMask,
    addNewCard,
    cardType,
    creditCard: { creditCardValid: isValid, name, number,expiration, validation} = {},
    typeChecker,
    errors,
    paymentType,
    hideCreditCards,
    verification,
    setVerificationMask,
    guestMode, 
  },
}) =>
  (paymentMethod === 'creditCard' || !!profileSettings || guestMode) && (
    <div className={classnames({ [styles.hideContainer]: hideCreditCards })}>
      {!profileSettings && !guestMode && (
        <SchemaParser schema={[header.chooseCard]} />
      )}
      <div className={styles.paymentOptions}>
        {existingCards && <SchemaParser schema={[existingCards]} />}
      </div>
      {existingCards && !newCardForm && !guestMode && (
        <button className={styles.newCardBtn} onClick={handleAddNewCard}>
          {label.addNew}
        </button>
      )}
      {(!existingCards || newCardForm || guestMode) && (
        <div className={styles.newCardFormContainer}>
          {(existingCards || guestMode) && (
            <SchemaParser schema={[header.newCard]} />
          )}
          {errors &&
            errors.map(error => (
              <div key={error} className={styles.creditCardError}>
                {error}
              </div>
            ))}
          <div
            className={classnames(
              styles.creditContainer,
              styles.creditCardName,
            )}
          >
            {store.getState().settings.payment_required_bit?<label htmlFor="name" className={styles.label}>{label.nameOnCard}<span style={{ color: 'red' }}>*</span></label>:<label htmlFor="name" className={styles.label}>{label.nameOnCard}</label>}
            <div className={styles.inputWrapper}>
              <input
                id="name"
                onChange={handleChange}
                type="text"
                name="name"
                payeezy-data="cardholder_name"
                value={name}
              />
            </div>
          </div>
          <div className={styles.creditCardNumber}>
            {store.getState().settings.payment_required_bit?<label htmlFor="creditCard" className={styles.label}>{label.cardNumber}<span style={{ color: 'red' }}>*</span></label>:<label htmlFor="creditCard" className={styles.label}>{label.cardNumber}</label>}
            <div className={styles.numberContainer} data-private>
              <InputMask
                id="number"
                onChange={handleChange}
                mask={inputMask}
                maskChar={null}
                onBlur={e => typeChecker(e, 'onBlur')}
                payeezy-data="cc_number"
                value={number}
              />
              {cardType && <div className={styles.cardType}>{cardType}</div>}
            </div>
          </div>
          <div className={styles.validation}>
            <div className={styles.valcode} data-private>
              {store.getState().settings.payment_required_bit?<label htmlFor="validation" className={styles.label}>{label.validationCode}<span style={{ color: 'red' }}>*</span></label>:<label htmlFor="validation" className={styles.label}>{label.validationCode}</label>}
              <InputMask
                id="validation"
                onChange={handleChange}
                onFocus={setVerificationMask}
                name="validation"
                mask={verification}
                maskChar={null}
                payeezy-data="cvv_code"
                value={validation}
              />
            </div>
            <div className={styles.expiration}>
              {store.getState().settings.payment_required_bit?<label htmlFor="expiration" className={styles.label}>{label.expirationDate}<span style={{ color: 'red' }}>*</span></label>:<label htmlFor="expiration" className={styles.label}>{label.expirationDate}</label>}
              <InputMask
                id="expiration"
                onChange={handleChange}
                onBlur={e => typeChecker(e, 'expCheck')}
                mask="99\ / 99"
                maskChar={null}
                placeholder="MM/YY"
                payeezy-data="expiration"
                value={expiration}
              />
            </div>
          </div>
          {saveCheckbox && (
            <SchemaParser
              schema={[
                { ...saveCheckbox, disabled: paymentType === 'paymentPlan' },
              ]}
            />
          )}
          {makeDefaultCheckBox && (
            <SchemaParser schema={[makeDefaultCheckBox]} />
          )}
          {profileSettings && existingCards && (
            <SchemaParser schema={[discard]} />
          )}
          {profileSettings && (
            <SchemaParser schema={[{ ...addNewCard, disabled: !isValid }]} />
          )}
        </div>
      )}
    </div>
  );

export const CreditCard = creditCard;
