import React, { Component } from 'react';
import { compose } from '@carecloud/cloudpak';
import { ContentPage } from '..';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { store } from '../../../models';
import { GetSettings } from '../../../graphql/queries';
import { ContextProvider } from '../../root/TopLevelContext';
import { breakBackButton } from '../../services/utilities';
import { settingsContext as SettingsContext } from '../../../contexts';
import * as Mutations from '../../../graphql/mutations';
import { HIDE_UPDATE_BUTTON_SECTIONS } from '../../../constants/ProfileSettings';
import styles from './ProfileSettingsContainer.module.scss';
import { SchemaParser } from '../../services';

const pathToSchemaMap = {
  '/profile_settings/personal': 'settingsPersonal',
  '/profile_settings/demographics': 'settingsDemographics',
  '/profile_settings/documents': 'settingsDocuments',
  '/profile_settings/payment': 'settingsPayment',
  '/profile_settings/notifications': 'settingsNotifications',
  '/profile_settings/authentication': 'settingsAuthentication',
  '/profile_settings/photo': 'settingsPhoto',
  '/profile_settings/manage_delegates': 'settingsManageDelegates',
};

class ProfileSettingsContainer extends Component {
  static contextType = ContextProvider;
  constructor(props) {
    super(props);
    this.state = {
      verifyOTPChangeEmailModal: null,
      verifyOTPUpdatePhoneModal: null,
      otp: null,
      resendType: null,
    };
  }

  async componentDidMount() {
    breakBackButton();
    let scrollTo= null
    if (store.getState().settings.view !== ''){
      scrollTo = store.getState().settings.view
    }
    store.dispatch.loader.activateLoader({ loading: true });
    const numberOfPractices = JSON.parse(sessionStorage.getItem('totalPractice'))
    let patientId
    let practiceMgmt
    let practiceId
    if(numberOfPractices === 1){
       patientId = JSON.parse(sessionStorage.getItem('Patient-Ids-List')).filter(x => x.practice_id === JSON.parse(sessionStorage.getItem('singleSelectedpractice')))?.[0]?.patient_id;
       practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
       practiceId = JSON.parse(sessionStorage.getItem('Patient-Ids-List')).filter(x => x.practice_id === JSON.parse(sessionStorage.getItem('singleSelectedpractice')))?.[0]?.practice_id;
    }else{
      patientId = JSON.parse(sessionStorage.getItem('Patient-Ids-List')).filter(x => x.practice_id === sessionStorage.getItem('practiceSelected'))?.[0]?.patient_id;
      practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
      practiceId = JSON.parse(sessionStorage.getItem('Patient-Ids-List')).filter(x => x.practice_id === sessionStorage.getItem('practiceSelected'))?.[0]?.practice_id;
    }
    const data = await this.props.client.query({ query: GetSettings, variables: { input: { withSchema: true,  patientId, practiceMgmt, practiceId } } });
    this.setState({
      verifyOTPChangeEmailModal: data.data.getSettings?.settingsSections?.settingsAuthentication?.verifyOTPChangeEmailModal,
      verifyOTPUpdatePhoneModal: data.data.getSettings?.settingsSections?.settingsAuthentication?.verifyOTPUpdatePhoneModal,
    })
    store.dispatch.settings.fetchStart(data.data.getSettings);
    store.dispatch.settings.setSection({ settingsSection: pathToSchemaMap[this.props.match.url] });
    store.dispatch.loader.activateLoader({ loading: false });

    scrollTo && document.getElementById(scrollTo).scrollIntoView();
    const getBit = data.data.getSettings.isrequired;
    store.dispatch.settings.setPaymentRequiredBit(getBit)
    this.context._setActions({
      authPasswordShow: this.authPasswordShow,
      passwordShownBtn: this.passwordShownBtn,
    });

  }

  componentDidUpdate() {
    this.passwordShownBtn();
    const {
      match: {
        url,
        params: { section },
      },
      settings: { settingsTop, settingsSection, refetch },
      loader: { loading },
    } = this.props;
    let isSmaalScreen = false;
    if(window.innerWidth <= 567){
      isSmaalScreen = true;
    }
    if (refetch && !loading) {
      store.dispatch.loader.activateLoader({ loading: true });
      this.refetch({ url });
      return store.dispatch.settings.didRefetch();
    }
    if (HIDE_UPDATE_BUTTON_SECTIONS.includes(url.split('/').pop())) {
      store.dispatch.button.deepHide({ id: 'profileSettingsUpdate' });
    } else {
      store.dispatch.button.deepHide({ id: [] });
    }

    if (settingsSection !== pathToSchemaMap[url]) {
      store.dispatch.settings.setSection({ settingsSection: pathToSchemaMap[url] });
      // unnecessary once all forms are migrated to jsonschma
      store.dispatch.formGroup.showForm(section);
    }
    settingsTop && settingsTop.length && loading && store.dispatch.loader.activateLoader({ loading: false });
    if (document.getElementById('twofaPhoneRadio')?.checked === true){
      document.getElementById('twofaEmailInputWrapper').style.display = 'none'
    }
    if (document.getElementById('twofaEmailRadio')?.checked === true){
      document.getElementById('twofaPhoneInputWrapper').style.display = 'none'
    }
    if (document.getElementById('twofaToggle')?.checked === false){
      document.getElementById('twofaEmailRadio').disabled = true
      document.getElementById('twofaPhoneRadio').disabled = true
    }
    else if (document.getElementById('twofaToggle')?.checked === true){
      document.getElementById('twofaEmailRadio').disabled = false
      document.getElementById('twofaPhoneRadio').disabled = false
    }

    if (isSmaalScreen) {
      const footerElement = document.getElementById('footer2');
      if (footerElement) {
        if (settingsSection === "settingsAuthentication" || settingsSection === "settingsPhoto") {
          footerElement.classList.add("FooterTop");

        } else {
          footerElement.classList.remove("FooterTop");
        }
      }
    }

  }

  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
    store.dispatch.settings.clearStore();
  }
  refetch = async ({ url }) => {
    store.dispatch.appointments.resetMissingFields()
    store.dispatch.appointments.clearStore()
    store.dispatch.settings.clearStore();
    const numberOfPractices = JSON.parse(sessionStorage.getItem('totalPractice'))
    let patientId
    let practiceMgmt
    let practiceId
    if(numberOfPractices === 1){
       patientId = JSON.parse(sessionStorage.getItem('Patient-Ids-List')).filter(x => x.practice_id === JSON.parse(sessionStorage.getItem('singleSelectedpractice')))?.[0]?.patient_id;
       practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
       practiceId = JSON.parse(sessionStorage.getItem('Patient-Ids-List')).filter(x => x.practice_id === JSON.parse(sessionStorage.getItem('singleSelectedpractice')))?.[0]?.practice_id;
    }else{
      patientId = JSON.parse(sessionStorage.getItem('Patient-Ids-List')).filter(x => x.practice_id === sessionStorage.getItem('practiceSelected'))?.[0]?.patient_id;
      practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
      practiceId = JSON.parse(sessionStorage.getItem('Patient-Ids-List')).filter(x => x.practice_id === sessionStorage.getItem('practiceSelected'))?.[0]?.practice_id;
    }
    const data = await this.props.client.query({ query: GetSettings, variables: { input: { withSchema: true,  patientId, practiceMgmt, practiceId } } });
    store.dispatch.settings.fetchStart(data.data.getSettings);
    store.dispatch.settings.setSection({ settingsSection: pathToSchemaMap[url] });



  };
  signOut = _ => {
    sessionStorage.clear();
    store.dispatch({ type: 'RESET' });
    this.props.history.push('/');
  };

  delegatePermissionsRequest = async ({ patientId, delegateUserId, practiceMgmt, practiceId, mutation }) => {
    store.dispatch.loader.persistLoader({ persist: true });
    const {
      data: {
        [mutation]: { status, noMoDelegates = false },
      },
    } = await this.props.client.mutate({
      mutation: Mutations[mutation],
      variables: {
        input: {
          patientId,
          delegateUserId,
          practiceMgmt,
          practiceId,
          ...(mutation === 'updateDelegatePermissions' && {
            permissionsObj: store.getState().hierarchicalCheckboxes.data,
          }),
        },
      },
    });
    store.dispatch.loader.persistLoader({ persist: false });
    const { errors } = store.getState().error;
    if (errors) {
      store.dispatch.modal.saveCustomMessage(errors?.[0]?.message);
      store.dispatch.error.resetError();
      return store.dispatch.modal.toggleOpen('manageProfilesErrorModal');
    }
    if (status === 200) {
      const { url } = this.props.match;
      this.refetch({ url });
      if (noMoDelegates) this.props.history.push('/profile_settings/personal');
    }
  };
  checkForEmptyInputOTP = ({ value }) => {
    const regex = new RegExp(/^\d{5}$/);
    if (value.length === 5 && regex.test(value)) {
      this.setState(prevState => {
        return {
          ...prevState,
          otp: value,
        };
      });
      store.dispatch.button.enableButton({
        btn: 'twofaVerifyBtn',
        complete: true,
      });
    }
    else {
      store.dispatch.button.enableButton({
        btn: 'twofaVerifyBtn',
        complete: false,
      });
    }
  };
  resendOTP = async _ => {
    store.dispatch.button.enableButton({
      btn: 'twofaResendBtn',
      complete: false,
    });
    store.dispatch.settings.setTimeDuration({duration: 300})
    const { events } = store.getState().formGroup
    const inputObj = {
      otp_type: this.state.resendType === 'phone' ? 'change_verification_phone' : 'change_email',
      phone_number: events.filter(e => e.id ==='twofaPhoneInput')?.at(-1)?.value,
    }
    try {
      const { data } = await this.props.client.mutate({
        mutation: Mutations.settingsResendOTP,
        variables: { input: inputObj },
      });
      if (data){
        return true
      }
    } catch (error) {
      store.dispatch.button.enableButton({
        btn: 'twofaResendBtn',
        complete: true,
      });
      return false
    }
  }
  updateTwoFA = async _ => {
    store.dispatch.loader.persistLoader({ persist: true });
    if (store.getState().modal.modalOpen){
      this.setState(prevState => {
        return {
          ...prevState,
          otp: null,
        };
      });
      store.dispatch.modal.toggleClose(false);
    }

    let inputObj = {}
    const { events } = store.getState().formGroup
    const twofaRadio = events.filter(e => e.id ==='twofaRadio')?.at(-1)?.value
    const twofaToggle = events.filter(e => e.id ==='twofaToggle')?.at(-1)?.value
    this.setState({resendType: twofaRadio})
    inputObj = {
        enabled: events.filter(e => e.id ==='twofaToggle')?.at(-1)?.value,
        settingsList: [
          {
            enabled: twofaToggle && twofaRadio === 'email',
            verification_type: "email",
          },
          {
            enabled: twofaToggle && twofaRadio === 'phone',
            verification_type: "sms",
            phone_number: events.filter(e => e.id ==='twofaPhoneInput')?.at(-1)?.value.split('-').join(""),
          },
        ],
        ...(this.state.otp && { otp: this.state.otp }), //send this in case you need to verify code for phone number change. When this property is not present and you are changing the phone number otp will be sent to the client
      }
    if (this.state.otp) {
      inputObj.otp = this.state.otp;
    }
    try {
      const { data } = await this.props.client.mutate({
        mutation: Mutations.updateTwoFA,
        variables: { input: inputObj },
      });
      store.dispatch.loader.persistLoader({ persist: false });

      // If not verified:
      // OTP sent to user on sms
      if (data.updateTwoFA?.otp_sent){
        // clear any previous OTP saved in state (from change email)
        this.setState(prevState => {
          return {
            ...prevState,
            otp: null,
          };
        });
        store.dispatch.button.enableButton({
          btn: 'twofaResendBtn',
          complete: false,
        });
        store.dispatch.modal.toggleOpen('verifyOTPUpdatePhoneModal');

      }
      else if (data?.updateTwoFA?.payload?.enabled !== undefined ) {
        // OTP preference updated. No verification needed.
        store.dispatch.settings.resetState();
        // TODO: Get toast message from cyclops
        store.dispatch.toast.open({ message: 'Two factor authentication preference updated', type: 'success'})
        return
      }

      // TODO: HANDLE 'UPDATE' BUTTON ON SUCCESS (DISABLE IT AS NEW VALUES HAVE BEEN SET. NOW NEW VALUES ARE INITIAL VALUES)
      // store.dispatch.button.enableButton({
      //   btn: 'twofaEnableBtn',
      //   complete: false,
      // });

    } catch (error) {
      console.error(error);
    }
  }

  authPasswordShow = passwordBtn => {
    const inputElement = document.getElementById(passwordBtn.passwordId);
    const eyeIcon = document.getElementById(passwordBtn.buttonId);
    const eyeShowPasswordSrc = 'https://assets.gobreeze.com/eye-password-show-svgrepo-com.svg';
    const eyeHidePasswordSrc = 'https://assets.gobreeze.com/eye-password-hide-svgrepo-com.svg';
    
    if (inputElement.type === 'password') {
      inputElement.type = 'text';
      eyeIcon.querySelector('img').src = eyeHidePasswordSrc; // Change the src to hide password image
    } else {
      inputElement.type = 'password';
      eyeIcon.querySelector('img').src = eyeShowPasswordSrc; // Change the src to show password image
    }
  }
  passwordShownBtn = () => {
    const inputElement = document.getElementById('profileSettingsNewPassword');
    const inputElement2 = document.getElementById('profileSettingsRepeatNewPassword');
    if(inputElement !== null || inputElement2 !== null){
      const inputValue = inputElement.value;
      const inputValue2 = inputElement2.value;
      const buttonElement = document.getElementById('eyeIcon');
      const buttonElement2 = document.getElementById('eyeIcon2');
      const imgElement = buttonElement.querySelector('img');
      const imgElement2 = buttonElement2.querySelector('img');
      if(inputValue === ''){
        imgElement.style.display = 'none';
        buttonElement.style.pointerEvents = 'none'
      }else{
        imgElement.style.display = 'block';
        buttonElement.style.pointerEvents = 'all'
      }
      if(inputValue2 === ''){
        imgElement2.style.display = 'none';
        buttonElement2.style.pointerEvents = 'none'
      }else{
        imgElement2.style.display = 'block';
        buttonElement2.style.pointerEvents = 'all'
      }
    }
  }

  updateEmail = async _ => {
    store.dispatch.loader.persistLoader({ persist: true });
    if (store.getState().modal.modalOpen){
      this.setState(prevState => {
        return {
          ...prevState,
          otp: null,
        };
      });
      store.dispatch.modal.toggleClose(false);
    }

    const inputObj = {};
    inputObj.currentPassword = store.getState().form.fields.currentPassword;
    inputObj.proposedEmail = store.getState().form.fields.proposedEmail;
    inputObj.loginEmail = sessionStorage.getItem('CC-Breeze-Web-username') || sessionStorage.getItem('CC-Breeze-Web-email');
    inputObj.deviceIdentifier = localStorage.getItem('device_identifier') || 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
    this.setState({resendType: 'email'})
    if (this.state.otp) {
      inputObj.otp = this.state.otp;
    }
    try {
      const { data } = await this.props.client.mutate({
        mutation: Mutations.changeEmail,
        variables: { input: inputObj },
      });
      if (data?.changeEmail?.verified) {
        if (data.changeEmail && data.changeEmail.authenticationToken) {
          sessionStorage.setItem(
            'CC-Breeze-Web-authenticationToken',
            data.changeEmail.authenticationToken,
          );
          sessionStorage.setItem('CC-Breeze-Web-username',
            data?.changeEmail?.username);
        }
        store.dispatch.loader.persistLoader({ persist: false });
        store.dispatch.modal.toggleClose(false);
        // Reset state otp on successful
        this.setState(prevState => {
          return {
            ...prevState,
            otp: null,
          };
        });
        store.dispatch.settings.resetState();
        store.dispatch.toast.open({ message: 'Email updated successfully', type: 'success'})
      }
      if (data?.changeEmail?.otp_sent) {
        this.setState(prevState => {
          return {
            ...prevState,
            otp: null,
          };
        });
        store.dispatch.loader.persistLoader({ persist: false });
        store.dispatch.button.enableButton({
          btn: 'twofaResendBtn',
          complete: false,
        });
        store.dispatch.modal.toggleOpen('verifyOTPChangeEmailModal');
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      settings: { settingsTop, settingsLeft, activeSection },
      loader: { loading },
    } = this.props;
    return !loading ? (
      <SettingsContext.Provider
        value={{
          revokeDelegateAccess: this.revokeDelegateAccess,
          signOut: this.signOut,
          delegatePermissionsRequest: this.delegatePermissionsRequest,
          toggleTwoFA: this.toggleTwoFA,
          verifyOTP: this.verifyOTP,
          resendOTP: this.resendOTP,
          updateEmail: this.updateEmail,
          updateTwoFA: this.updateTwoFA,
          checkForEmptyInputOTP: this.checkForEmptyInputOTP,
        }}
      >
        <SchemaParser schema={[this.state.verifyOTPChangeEmailModal]} />
        <SchemaParser schema={[this.state.verifyOTPUpdatePhoneModal]} />
        <ContentPage
          splitSchemas={[settingsTop, settingsLeft, activeSection]}
          useGrid
          className={styles.profile_settings_grid_styles}
          schemas={settingsTop}
          loading={loading}
          withPadding
        />
      </SettingsContext.Provider>)
      : <div className={styles.emptyContainer} />
  }
}
const mapStateToProps = ({ settings, loader, formGroup }) => ({ settings, loader, formGroup });
const enhancer = compose(withRouter, withApollo, connect(mapStateToProps));
export default enhancer(ProfileSettingsContainer);