import { get } from '@carecloud/cloudpak';
import { AddAppointment as AddAppointmentConstants } from '../constants/index';

export const addAppointment = {
  state: {
    addAppointment: [],
    providerCards: [],
    filteredProviderCards: [],
    visitTypes: [],
    filteredVisitTypes: [],
    noSearchResults: [],
    practiceDropdownSchema: {},
    appointmentProvider: String(),
    rescheduleAppointment: {},
    checkoutAppointment: {},
    selectedProviderId: NaN,
    visitReasonId: NaN,
    prePayment: 0,
    visitType: String(),
    practiceId: String(),
    practiceMgmt: String(),
    patientId: String(),
    locationId: NaN,
    filteredLocations: [],
    initialLocations: [],
    selectedProvider: {},
    filteredValue: String(),
    providerFilterValue: String(),
    visitTypeFilterValue: String(),
    fromReschedule: false,
  },
  reducers: {
    fetchStart: (state, { addAppointmentScreen: { appointmentWorkflowMain = {} } = {} }) => ({
      ...state,
      addAppointment: appointmentWorkflowMain,
      initialLocations: appointmentWorkflowMain.addAppointment.locations,
      filteredLocations: appointmentWorkflowMain.addAppointment.locations,
      providerCards: appointmentWorkflowMain.addAppointment.providerCards,
      filteredProviderCards: appointmentWorkflowMain.addAppointment.providerCards,
      visitTypes: appointmentWorkflowMain.addAppointment.visitTypes,
      filteredVisitTypes: appointmentWorkflowMain.addAppointment.visitTypes,
      noSearchResults: appointmentWorkflowMain.addAppointment.noSearchResults,
    }),
    refetch: (state, { addAppointmentScreen: { appointmentWorkflowMain = {} } = {}, exceptFor = String() } = {}) => {
      const { visitTypes = [], locations = [], providerCards = [] } = get(
        appointmentWorkflowMain,
        'addAppointment',
        {},
      );
      const exceptMapping = {
        [AddAppointmentConstants.LOCATIONS]: {
          visitTypes,
          filteredVisitTypes: visitTypes,
          providerCards,
          filteredProviderCards: providerCards,
        },
        [AddAppointmentConstants.VISIT_REASONS]: {
          initialLocations: locations,
          filteredLocations: locations,
          providerCards,
          filteredProviderCards: providerCards,
        },
        [AddAppointmentConstants.RESOURCES]: {
          initialLocations: locations,
          filteredLocations: locations,
          visitTypes,
          filteredVisitTypes: visitTypes,
        },
      }[exceptFor];
      return {
        ...state,
        addAppointment: appointmentWorkflowMain,
        ...exceptMapping,
      };
    },
    setPracticeDropdownSchema: (state, { practiceDropdownSchema }) => ({
      ...state,
      practiceDropdownSchema,
    }),
    filterLocations: (state, { filteredLocations }) => ({
      ...state,
      filteredLocations,
    }),
    filterProviders: (state, { filteredProviderCards }) => ({
      ...state,
      filteredProviderCards,
    }),
    filterVisitTypes: (state, { filteredVisitTypes }) => ({
      ...state,
      filteredVisitTypes,
    }),
    setProvider: (state, selectedProvider) => ({
      ...state,
      selectedProvider,
      practiceMgmt: selectedProvider.practiceMgmt,
      visitReasonId: selectedProvider.visitReasonId,
      selectedProviderId: selectedProvider.providerId,
    }),
    selectProvider: (
      state,
      {
        resourceId,
        practiceId,
        patientId,
        provider: { name: providerName, speciality: { name: specialtyName } = {} } = {},
        provider,
        practiceMgmt,
        providerId,
        providerGuid,
        autoSchedule,
        appointmentProvider,
        selectedProviderId,
        avatar: { url } = {},
      },
    ) => ({
      ...state,
      appointmentProvider,
      selectedProviderId,
      selectedProvider: {
        ...state.selectedProvider,
        resourceId,
        practiceId,
        patientId,
        provider,
        name: providerName,
        specialty: specialtyName,
        practiceMgmt,
        providerId,
        providerGuid,
        ...(autoSchedule ? { autoSchedule } : {}),
        photo: url,
      },
    }),
    selectVisitReason: (state, { visitReasonId, prePayment, visitType }) => ({
      ...state,
      visitReasonId,
      prePayment: (prePayment && prePayment.amount) || 0,
      visitType,
    }),
    selectLocation: (
      state,
      {
        locationId,
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        state: stateName,
        zip,
        phone,
        office,
        locationGuid,
      },
    ) => ({
      ...state,
      locationId,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      state: stateName,
      zip,
      phone,
      office,
      locationGuid,
    }),
    setPracticePatientIds: (state, { practiceId, practiceMgmt, patientId }) => ({
      ...state,
      practiceId,
      practiceMgmt,
      patientId,
    }),
    resetSelectedOptions: state => ({
      ...state,
      prePayment: 0,
      practiceId: String(),
      practiceMgmt: String(),
      patientId: String(),
      appointmentProvider: String(),
      selectedProviderId: NaN,
      visitReasonId: NaN,
      visitType: String(),
      locationId: NaN,
      filteredProviderCards: [],
      filteredVisitTypes: [],
      filteredLocations: [],
      initialLocations: [],
      selectedProvider: {},
      filteredValue: String(),
      providerFilterValue: String(),
      visitTypeFilterValue: String(),
      fromReschedule: false,
    }),
    clearStore: state => ({
      ...state,
      addAppointment: [],
      providerCards: [],
      filteredProviderCards: [],
      visitTypes: [],
      filteredVisitTypes: [],
      filteredLocations: [],
      initialLocations: [],
    }),
    setFilterValue: (state, { filteredValue }) => ({
      ...state,
      filteredValue,
    }),
    setProviderFilterValue: (state, { providerFilterValue }) => ({
      ...state,
      providerFilterValue,
    }),
    setVisitTypeFilterValue: (state, { visitTypeFilterValue }) => ({
      ...state,
      visitTypeFilterValue,
    }),
    clearFilterValue: state => ({
      ...state,
      filteredValue: String(),
    }),
    clearProviderFilterValue: state => ({
      ...state,
      providerFilterValue: String(),
    }),
    clearVisitTypeFilterValue: state => ({
      ...state,
      visitTypeFilterValue: String(),
    }),
    setPrePayment: (state, prePayment) => ({
      ...state,
      prePayment,
    }),
    selectDate: (state, { startDate, endDate }) => ({
      ...state,
      startDate,
      endDate,
    }),
    addReasonForVisit: (state, visitReason) => ({
      ...state,
      visitReason,
    }),
    setFromReschedule: state => ({ ...state, fromReschedule: true }),
  },
};
