import React, { memo, useState, useContext, useEffect } from 'react';
import { isEmpty, isStringifiedJson } from '@carecloud/cloudpak';
import moment from 'moment';
import { formatMoney } from '../../services/utilities';
import { Input } from './input';
import { store } from '../../../models';
import * as babyContexts from '../../../contexts';
import { ContextProvider } from '../../root/TopLevelContext';

const InputContainer = memo(props => {
  const {
    defaultValue,
    value,
    resetValues,
    id,
    formContext,
    formData,
    changeValue,
    schema,
    uiSchema,
    mask,
    maskCondition,
    type,
    validations,
    format,
    formGroupId,
    action = {},
    formMapping,
    required,
    name,
    disabled,
    clearOnUnmount,
    onChange: jsonSchemaOnChange,
  } = props;
  const { model = String(), trigger = String(), babyContext = String() } = action;
  const grandpaContext = useContext(ContextProvider);
  const variableContext = babyContext && useContext(babyContexts[`${babyContext}Context`]);
  const isJsonSchema = !isEmpty(formContext);
  const [input, setInput] = useState(resetValues ? '' : defaultValue || value);
  const [loginInput] = useState(id === 'email' || id === 'password');
  const [errors, setErrors] = useState([]);
  const [displayOneErrorOnly, setDisplayOneErrorOnly] = useState([false]);

  const formIdNumber = ({ formGroupId = '' }) => {
    const uniqueIdNumber = formGroupId.match(/\d+/g);
    return uniqueIdNumber ? uniqueIdNumber[0] : '';
  };

  const stripMasking = ({ input }) => {
    if (!mask || !input) return input;
    const maskArray = mask.split('').map(char => (char === '9' ? '_' : char));
    const strippedInput = input
      .split('')
      .map(char => !(char === maskArray.shift() || char === '_') && char)
      .filter(char => char)
      .join('');
    return strippedInput;
  };

  const getMask = _ => {
    if (isJsonSchema) return uiSchema.mask;
    if (isEmpty(maskCondition)) return mask;
    const unMaskedInput = stripMasking({ input }) || String();
    const { condition, value } = maskCondition;
    const conditionEvaluator = { lengthGreater: _ => (unMaskedInput.length > value ? mask : undefined) };
    return conditionEvaluator[condition]();
  };

  // Used to check match validation on individual input containers
  const checkFieldsMatchValidation = ({ id, error_message }, value) => {
    setDisplayOneErrorOnly(true);
    const fieldEvents = store.getState().formGroup.events;
    const errors = [];
    let numOfFields = 0;
    //check how many times field is in events array, fields will appear more than once after being edited
    fieldEvents.forEach(input => {
      if (input.id === id) {
        numOfFields++;
      }
    });
    fieldEvents.forEach(input => {
      if (input.id === id) {
        // if field is repeated then make sure is not initial value
        if (!isEmpty(value) && value !== input.value && ((numOfFields > 1 && !input.isInitial) || numOfFields === 1)) {
          errors.push(error_message);
        }
      }
    });
    return errors;
  };

  const checkDateOfBirth = ({ inputDates, validation }) => {
    const dates = inputDates.split('/');
    const d = dates[0];
    const m = dates[1];
    const y = dates[2];
    return new Date(`${d}-${m}-${y}`) > new Date() ? false : new RegExp(validation.value).test(inputDates);
  };

  const dispatchAction = e => {
    const { value, id, isInitial = undefined } = e;
    let fieldValid = true;
    let paymentValid = true;
    if (validations) {
      const validation = validations.find(obj => obj.type.toLowerCase() === 'pattern');
      const numRange = validations.find(obj => obj.type.toLowerCase() === 'numrange');
      const match = validations.find(obj => obj.type.toLowerCase() === 'match');
      if (validation && value) {
        if (id === 'guestRegisterDateOfBirth' || id === 'signupInputDOB') {
          fieldValid = checkDateOfBirth({ inputDates: value, validation });
        } else {
          format !== 'money'
            ? (fieldValid = new RegExp(validation.value).test(value))
            : (fieldValid =
                new RegExp(validation.value).test(value.replace(/[^\d.]/g, '')) &&
                value.replace(/[^\d.]/g, '') <= +numRange.max);
        }
      }
      if (match && value) {
        setErrors(checkFieldsMatchValidation(match, value));
      }
      if (numRange && value && format === 'money') {
        const formatValue = value.replace(/[^\d.]/g, '');
        paymentValid = formatValue <= +numRange.max && formatValue >= +numRange.min;
      } else {
        paymentValid = numRange && value && format === 'money';
      }
    }
    try {
      const formId = formIdNumber({ formGroupId });
      let newFormMapping = formMapping;
      if (formMapping && formMapping.match(/(\[\*\])/)) {
        newFormMapping = formMapping.replace(/(\[\*\])/, `[${formId || 0}]`);
      }
      let newId = id;
      if (newId.match(/\d+/g)) {
        newId = newId.replace(/\d+/g, formId);
      } else {
        newId += formId;
      }
      if (!isEmpty(action)) {
        const param = { id: newId, value, fieldValid, paymentValid, isInitial, formMapping: newFormMapping };
        if (babyContext) variableContext[trigger](param);
        if (model === 'localActions') grandpaContext._getAction(trigger) && grandpaContext._getAction(trigger)(param);
        else store.dispatch[model][trigger](param);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getValue = _ => {
    if (isJsonSchema) return formData;
    const returnValue = changeValue === undefined ? value || input : changeValue;
    if (!getMask()) return stripMasking({ input: returnValue });
    return returnValue;
  };
  const isDisable = _ => {
    const schemaParsed = isStringifiedJson(schema) ? JSON.parse(schema) : schema;
    return isJsonSchema && schemaParsed.disable;
  };

  const getType = _ => {
    if (isJsonSchema) return uiSchema.type;
    return type;
  };
  const getUpdatedDate = _ => {
    const schemaParsed = isStringifiedJson(schema) ? JSON.parse(schema) : schema;
    if (isJsonSchema && schemaParsed && schemaParsed.updatedDate)
      return moment(schemaParsed.updatedDate).format('MM/DD/YYYY');
    return moment().format('MM/DD/YYYY');
  };
  const getOnChange = _ => {
    if (isJsonSchema) {
      return e => jsonSchemaOnChange(e.target.value);
    }
    return e => {
      dispatchAction(e.target);
      setInput(e.target.value);
    };
  };

  const handleFormatMoney = input => {
    const number = +input.replace(/[^\d.]/g, '');
    if (new RegExp('^(?:[0-9]+(?:.[0-9]{0,2})?)?$').test(number)) {
      const formattedNumber = formatMoney({ amount: number });
      return `$${formattedNumber}`;
    }
  };

  const handleValidation = e => {
    const { validations = [], format, action: { model = String(), trigger = String() } = Object() } = isJsonSchema
      ? uiSchema
      : props;
    let input = e.target.value;
    const validation = validations.find(obj => obj.type.toLowerCase() === 'pattern');
    const required = validations.find(obj => obj.type.toLowerCase() === 'required');
    const numRange = validations.find(obj => obj.type.toLowerCase() === 'numrange');
    const match = validations.find(obj => obj.type.toLowerCase() === 'match');
    const inputErrors = [];
    if (e.target.id === 'guestRegisterDateOfBirth' || e.target.id === 'signupInputDOB') {
      const inputDates = input;
      !checkDateOfBirth({ inputDates, validation }) && inputErrors.push(validation.error_message);
    } else {
      input &&
        validation &&
        !new RegExp(validation.value).test(format !== 'money' ? input : input.replace(/[^\d.]/g, '')) &&
        inputErrors.push(validation.error_message);
      input &&
        numRange &&
        (input.replace(/[^\d.]/g, '') < +numRange.min || input.replace(/[^\d.]/g, '') > +numRange.max) &&
        inputErrors.push(numRange.error_message);
      if (input && match) {
        inputErrors.push(checkFieldsMatchValidation(match, input));
      }
      if (required && required.value && (!input || input === '0.00') && !loginInput) {
        inputErrors.push(required.error_message);
      } else if (isJsonSchema && required && !input) {
        inputErrors.push('Required Field');
      }
      isJsonSchema && formContext.addErrors && formContext.addErrors(name, inputErrors);
      if (format === 'money' && input.length) {
        input = handleFormatMoney(input);
      }
      if (format === 'money' && inputErrors.length === 0) {
        if (!isEmpty(action)) {
          const param = { id: e.target.id, value: input };
          if (babyContext) variableContext[trigger](param);
          else store.dispatch[model][trigger](param);
        }
      }
    }
    setErrors(inputErrors);
  };

  const mapJsonSchemaProps = _ => {
    if (!isJsonSchema) return {};
    const schemaParsed = isStringifiedJson(schema) ? JSON.parse(schema) : schema;
    const { title, description } = schemaParsed;
    const { mask, placeholder, type, requiredLabel, errorRequiredLabel, validations = [] } = uiSchema;
    return {
      label: title,
      requiredLabel: required ? requiredLabel : String(),
      errorRequiredLabel,
      mask,
      placeholder,
      type,
      supportingText: description,
      validations,
    };
  };

  const cleanup = _ => {
    if (isJsonSchema || !clearOnUnmount) return;
    dispatchAction({ id, value: String() });
  };

  useEffect(() => {
    if (isJsonSchema) return;
    let fieldValue = resetValues ? '' : defaultValue || value;
    if (getMask() && fieldValue) {
      const placeHolders = ['9', 'a', '*'];
      const fieldValueArray = fieldValue.replace(/[() -]/g, '').split('');
      const delimiters = mask.split('');
      fieldValue = delimiters.map(value => (!placeHolders.includes(value) ? value : fieldValueArray.shift())).join('');
    }
    if (!isEmpty(action)) {
      dispatchAction({ id, value: fieldValue, isInitial: true });
    }
    return cleanup();
  }, []);
  return (
    <Input
      {...{
        ...props,
        ...mapJsonSchemaProps(),
        type: getType(),
        maskInput: getMask(),
        onChange: getOnChange(),
        value: getValue(),
        updatedDate: getUpdatedDate(),
        handleValidation,
        errors,
        handleFormatMoney,
        disabled: isDisable() || (disabled && !resetValues),
        displayOneErrorOnly,
      }}
    />
  );
});

export default InputContainer;
