import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import classNames from 'classnames';
import { store } from '../../../models';
import { ContentPage } from '..';
import {
  GetGuestAlreadyDone,
  GetGuestAppointment,
  GetGuestPay,
  GetGuestPayNoCode,
  GetDelegateVerification,
  GetDelegateLogin,
  GetDelegateCreateAccount,
  GetDelegateForgotPassword,
  GetSmsSignupConfirmation,
} from '../../../graphql/queries';
import { Guest as GuestConstants } from '../../../constants/index';
import { PageLoader } from '../../ui/page_loader/pageLoader';
import { camelCaseAppend, parseResult } from '../../../graphql/utilities';
import { isMobileDevice, breakBackButton } from '../../services/utilities';
import { parseParams } from '../../services/routing';
import styles from './GuestEntry.module.scss';
import { ContextProvider } from '../../root/TopLevelContext';

class GuestEntryContainer extends Component {
  static contextType = ContextProvider;
  componentDidMount() {
    this.context._setActions({ navigateGuestRoute: this.navigateGuestRoute, changeLanguage: this.changeLanguage });
    store.dispatch.loader.activateLoader({ loading: true });
    store.dispatch.guestEntry.setGuestMode(true);
    breakBackButton();
  }

  componentDidUpdate() {
    const {
      loader: { loading },
      formGroup,
      match: { params: { section } },
      guestEntry: { guestScreen, reloadGuest },
    } = this.props;
    const queryName = `get${[camelCaseAppend(section)]}`;
    const query = this.props[queryName];
    const queryLoading = query && query.loading;
    if (!queryLoading && loading && !reloadGuest) store.dispatch.loader.activateLoader({ loading: false });
    if ((isEmpty(guestScreen) || reloadGuest) && !queryLoading) {
      store.dispatch.guestEntry.setReloadGuest(false);
      parseResult({
        query,
        dataField: queryName,
        resultHandler: store.dispatch.guestEntry.fetchStart,
      });
      store.dispatch.loader.persistLoader({ loading: false });
    }
    // guest register view
    if (section !== formGroup.formShown && !GuestConstants.FORMLESS_ENTRIES.includes(section)) {
      store.dispatch.formGroup.showForm(section);
      store.dispatch.loader.persistLoader({ loading: false });
    }
  }

  navigateGuestRoute = ({ route, clearParams = false }) => {
    const {
      history: { push },
      location: { search },
    } = this.props;
    store.dispatch.guestEntry.setReloadGuest(true);
    store.dispatch.loader.activateLoader({ loading: true });
    clearParams ? push(route) : push(`${route}${search}`);
  };

  changeLanguage = language => {
    if (localStorage.getItem('CC-Breeze-Web-language') === language) {
      store.dispatch.dropdown.toggleOpen('loginLanguageMenu');
      return;
    }
    localStorage.setItem('CC-Breeze-Web-language', language);
    window.location.reload();
  };

  render() {
    const {
      loader: { loading, persist },
      guestEntry: { guestScreen },
      match: { params: { section } },
    } = this.props;
    return (
      guestScreen && (
        <div
          className={classNames(
            isMobileDevice() ? styles.guestEntryWrapperMobile : styles.guestEntryWrapper,
            styles[`${section}Wrapper`],
            { [styles[`${section}WrapperMobile`]]: isMobileDevice() },
          )}
        >
          <ContentPage
            className={isMobileDevice() ? styles.guestMobileContentPage : styles.guestContentPage}
            schemas={[guestScreen]}
            loading={loading}
          />
          {(loading || persist) && <PageLoader loading={loading} />}
        </div>
      )
    );
  }
}
const mapStateToProps = ({ guestEntry, loader, formGroup }) => ({
  guestEntry,
  loader,
  formGroup,
});
const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps),
  graphql(GetGuestAppointment, {
    name: 'getGuestAppointment',
    skip: ({ match: { params: { section } } }) => section !== 'guestAppointment',
  }),
  graphql(GetGuestPay, {
    name: 'getGuestPay',
    options: props => ({ variables: { input: { code: parseParams(props).code || String(), guestPayLanding: true } } }),
    skip: ({ match: { params: { section } } }) => section !== 'guestPay',
  }),
  graphql(GetGuestPayNoCode, {
    name: 'getGuestPayNoCode',
    skip: ({ match: { params: { section } } }) => section !== 'guestPayNoCode',
  }),
  graphql(GetGuestAlreadyDone, {
    name: 'getGuestAlreadyDone',
    options: props => ({ variables: { input: { whatDone: parseParams(props).done || String() } } }),
    skip: ({ match: { params: { section } } }) => section !== 'guestAlreadyDone',
  }),
  graphql(GetDelegateVerification, {
    name: 'getDelegateVerification',
    options: props => ({ variables: { input: { invitationCode: parseParams(props).code || String() } } }),
    skip: ({ match: { params: { section } } }) => section !== 'delegateVerification',
  }),
  graphql(GetDelegateLogin, {
    name: 'getDelegateLogin',
    options: props => ({ variables: { input: { invitationCode: parseParams(props).code || String() } } }),
    skip: ({ match: { params: { section } } }) => section !== 'delegateLogin',
  }),
  graphql(GetDelegateForgotPassword, {
    name: 'getDelegateForgotPassword',
    skip: ({ match: { params: { section } } }) => section !== 'delegateForgotPassword',
  }),
  graphql(GetDelegateCreateAccount, {
    name: 'getDelegateCreateAccount',
    options: props => ({ variables: { input: { invitationCode: parseParams(props).code || String() } } }),
    skip: ({ match: { params: { section } } }) => section !== 'delegateCreateAccount',
  }),
  graphql(GetSmsSignupConfirmation, {
    name: 'getSmsSignupConfirmation',
    options: props => ({ variables: { email: parseParams(props).email || String() } }),
    skip: ({ match: { params: { section } } }) => section !== 'smsSignupConfirmation',
  }),
);
export default enhancer(GuestEntryContainer);
