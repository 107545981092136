/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { withApollo } from 'react-apollo';
import { compose } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import * as CryptoJS from 'crypto-js';
import { withRouter } from 'react-router-dom';
import creditCardType from 'credit-card-type';
import moment from 'moment';
import { store } from '../../../models';
import { AddCreditCard, deleteCreditCard } from '../../../graphql/mutations';
import { CreditCard } from './creditCard';
import { Paths } from '../../../constants';
import styles from './creditCard.module.scss';
import { creditCardContext as CreditCardContext } from '../../../contexts';

class CreditCardContainer extends PureComponent {
  state = { cardAttributes: {} };

  componentDidMount() {
    store.dispatch.formGroup.change({
      id: 'defaultCard',
      value: null,
      isInitial: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    const {
      creditCard,
      radioInput: { selectedExistingCard, newCardForm, paymentType },
      guestEntry: { guestMode },
    } = nextProps;
    const { toggleNewCardForm, radioInput, resetCard, resetPaymentMethod, clearSelectedCard } = this.props;
    if (selectedExistingCard && newCardForm) toggleNewCardForm(false);
    if (paymentType !== radioInput.paymentType) {
      !guestMode && resetCard();
      toggleNewCardForm(false);
      this.setState({}, () => {
        this.setState({ cardAttributes: {} });
      });
    }
    if (newCardForm !== radioInput.newCardForm) {
      this.setState({}, () => {
        this.setState({ cardAttributes: {} });
      });
      resetCard();
    }
    if (creditCard && creditCard.number) {
      creditCard.number.replace(/\s/g, '') && this.cardDetector(creditCard);
    }
    this.validator(creditCard, selectedExistingCard);
    this.typeChecker(creditCard);
  }

  componentWillUnmount() {
    const { resetCard, resetPaymentMethod } = this.props;
    resetCard();
    resetPaymentMethod();
  }
  setVerificationMask = _ => {
    const { codeLength } = this.state.cardAttributes;
    this.setState({ verification: codeLength === 3 ? '999' : '9999' });
  };
  cardDetector = creditCard => {
    const { number } = creditCard;
    const visaCards = creditCardType(number && number.replace(/\s/g, ''));
    if (number && number.replace(/\s/g, '').length > 3 && visaCards.length > 0) {
      const { type, code, lengths } = visaCards[0];
      const codeLength = code.size;
      const length = lengths[0];
      type === 'american-express' || type === 'diners-club'
        ? this.setState({
            cardAttributes: {
              type: type.replace(/-/g, ' '),
              length,
              codeLength,
              inputMask: '9999 999999 99999',
            },
          })
        : this.setState({
            cardAttributes: {
              type: type === 'master-card' ? type.replace(/-/g, '') : type.replace(/-/g, ' '),
              length,
              inputMask: '9999 9999 9999 9999',
              codeLength,
            },
          });
    }
  };
  typeChecker = (creditCard, onBlur) => {
    const { label } = this.props;
    let cardNumber = null;
    const errors = [];
    cardNumber = creditCard.number;
    if (onBlur) {
      cardNumber = creditCard.target.value;
      if (onBlur === 'expCheck') {
        const expirationDate = moment(`01/${cardNumber.replace(/ /g, '')}`).format('DD/MM/YY');
        const notExp = moment(expirationDate).isSameOrAfter(
          moment()
            .startOf('month')
            .format(),
        );
        if (!notExp) errors.push(label.invalidDate);
      }
    }
    const { type } = this.state.cardAttributes;
    const acceptedTypes = ['visa', 'mastercard', 'american express', 'jcb', 'discover'];
    if (cardNumber) {
      const cardLength = cardNumber.replace(/\s/g, '').length;
      if (type && cardLength && !acceptedTypes.includes(type)) {
        errors.push(label.cardNotSupported);
      } else if (type === undefined && cardLength > 5 && cardLength < 14) {
        errors.push(label.invalidCard);
      }
      this.setState({ errors });
    }
  };
  validator = (creditCard, selectedExistingCard) => {
    const { number, expiration, validation, name, creditCardValid } = creditCard;
    const { toggleValid } = this.props;
    let valid = selectedExistingCard;
    if (number && expiration && validation && name) {
      const expirationDate = moment(`01/${expiration.replace(/ /g, '')}`).format('DD/MM/YY');
      const notExp = moment(expirationDate).isSameOrAfter(
        moment()
          .startOf('month')
          .format(),
      );
      const { length, codeLength } = this.state.cardAttributes;
      valid =
        number.replace(/\s/g, '').length >= length &&
        expiration.replace(/\s/g, '').length === 5 &&
        notExp &&
        validation.replace(/\s/g, '').length === codeLength &&
        name.replace(/\s/g, '').length > 3 &&
        this.state.errors.length === 0;
    }
    if (valid !== creditCardValid) {
      toggleValid(valid);
    }
    store.getState().loader.persist && store.dispatch.loader.persistLoader({ persist: false });
  };
  handleAddNewCard = _ => {
    const { toggleNewCardForm, clearSelectedCard } = this.props;
    toggleNewCardForm(true);
    clearSelectedCard();
  };
  handleSaveCard = _ => {
    store.dispatch.loader.persistLoader({ persist: true });
    this.saveCard();
  };

  saveCard = async _ => {
    const { validation, name, expiration, number } = store.getState().creditCard;
    const { merchant } = this.props;

    // NEED ASYMMETRIC ENCRYPTION HERE
    // const encryptedCardData = CryptoJS.AES.encrypt(JSON.stringify({
    //   type: this.state?.cardAttributes?.type?.toUpperCase(),
    //   cardholder_name: name,
    //   card_number: number.replace(/\s/g, ''),
    //   exp_date: expiration && expiration.replace(/\D/g, ''),
    //   cvv: validation,
    // }), 'secret key 123').toString();
    const encryptedMerchantData = merchant; 
    const bytes = CryptoJS.AES.decrypt(encryptedMerchantData, 'merchantKey');
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const plainTextCardData = JSON.stringify({
      type: this.state?.cardAttributes?.type?.toUpperCase(),
      cardholder_name: name,
      card_number: number.replace(/\s/g, ''),
      exp_date: expiration && expiration.replace(/\D/g, ''),
      cvv: validation,
      merchant:decryptedData,
      saveCard: 'true',
    });

    const data = await this.props.client.mutate({
      mutation: AddCreditCard,
      variables: {
        input: {
          plainTextCardData,
        },
      },
    });
    if (data?.data?.addCreditCard?.status === 200) {
      store.dispatch.radioInput.toggleNewCardForm(false);
      store.dispatch.settings.resetState();
      store.dispatch.mixpanel.addMetadata({ creditCardUpdate: true });
    } else {
      store.dispatch.toast.open({
        type: 'error',
        message: store.getState().error.errors?.[0]?.message,
      });
    }
    store.dispatch.loader.persistLoader({ persist: false });
  };

  handleChange = e => {
    const { updateCard } = this.props;
    if (e.target.value.trim().length >= 0) {
      updateCard({ id: e.target.id, value: e.target.value });
    }
  };
  makeDefault = ({ param, value, isInitial, formMapping }) => {
    if (isInitial && !value) return;
    store.dispatch.mixpanel.addMetadata({ creditCardUpdate: true });
    store.dispatch.formGroup.change({
      id: 'defaultCard',
      formMapping,
      value: value && param,
      isInitial,
    });
  };
  deleteCard = async cardId => {
    const { loader, modal, settings } = store.dispatch;
    loader.persistLoader({ persist: true });
    try {
      const { data: { deleteCreditCard: { message, status } = {} } = {} } = await this.props.client.mutate({
        mutation: deleteCreditCard,
        variables: { input: { cardId } },
      });
      if (status === 200 && this.props.profileSettings) {
        settings.resetState();
      }
      if (status === 400) {
        modal.saveCustomMessage(message);
        modal.toggleOpen('creditCardDeleteErrorModal');
      }
    } catch (e) {
      console.error(e);
    }
    loader.persistLoader({ persist: false });
  };
  render() {
    const {
      classes,
      radioInput: { paymentMethod, newCardForm, selectedExistingCard, paymentType },
      creditCard,
      location: { pathname },
      guestEntry: { guestMode },
      label,
      discard,
      existingCards,
      makeDefaultCheckBox,
      profileSettings,
      addNewCard,
      saveCheckbox,
      header,
      id,
    } = this.props;

    return (
      <CreditCardContext.Provider
        value={{
          handleSaveCard: this.handleSaveCard,
          deleteCard: this.deleteCard,
          makeDefault: this.makeDefault,
        }}
      >
        <CreditCard
          props={{
            classes,
            id,
            creditCard,
            location: { pathname },
            guestEntry: { guestMode },
            label,
            discard,
            existingCards,
            makeDefaultCheckBox,
            profileSettings,
            addNewCard,
            saveCheckbox,
            header,
            guestMode,
            paymentMethod,
            paymentType,
            newCardForm,
            selectedExistingCard,
            hideCreditCards: pathname.includes(Paths.EDIT_ONE_TIME),
            handleChange: this.handleChange,
            handleAddNewCard: this.handleAddNewCard,
            inputMask: this.state.cardAttributes.inputMask,
            cardType: this.state.cardAttributes.type,
            typeChecker: this.typeChecker,
            notSupported: this.state.notSupported,
            invalidCard: this.state.invalidCard,
            errors: this.state.errors,
            verification: this.state.verification,
            setVerificationMask: this.setVerificationMask,
          }}
        />
      </CreditCardContext.Provider>
    );
  }
}

// CreditCardContainer.whyDidYouRender = true;

const mapStateToProps = ({ creditCard, radioInput, guestEntry, settings }) => ({
  creditCard,
  radioInput,
  guestEntry,
  settings,
});
const mapDispatchToProps = ({ creditCard, radioInput, paymentPlan }) => ({
  updateCard: creditCard.updateCard,
  resetCard: creditCard.resetCard,
  toggleValid: creditCard.toggleValid,
  resetPaymentMethod: radioInput.resetPaymentMethod,
  toggleNewCardForm: radioInput.toggleNewCardForm,
  clearSelectedCard: radioInput.clearSelectedCard,
  resetPaymentPlan: paymentPlan.resetPaymentPlan,
});

const enhancer = compose(withRouter, withApollo, connect(mapStateToProps, mapDispatchToProps));
export default enhancer(CreditCardContainer);
