export const settings = {
  state: { settingsTop: [], settingsLeft: [], settingsSections: {}, settingsSection: '', activeSection: [], view: '', timeDurationOTP: 0 ,required_Bit:null,required_Bit_2:null,payment_required_bit:null,insuranceDetails:false},

  reducers: {
    fetchStart: (state, { settingsTop, settingsLeft, settingsSections }) => ({
      ...state,
      settingsTop,
      settingsLeft,
      settingsSections,
    }),
    setSection: (state, { settingsSection }) => ({
      ...state,
      activeSection: [state.settingsSections[settingsSection]],
      settingsSection,
    }),
    setTimeDuration: (state, { duration }) => ({
      ...state,
      timeDurationOTP: duration,
    }),
    PICTURE_INSURANCE_CARD: (state,  bit ) => ({
      ...state,
        required_Bit: bit,
    }),
    Document_Forntphoto: (state,  bit ) => ({
      ...state,
      required_Bit_2: bit,
    }),
    setPaymentRequiredBit: (state, bit ) => ({
      ...state,
      payment_required_bit: bit,
    }),
    insuranceInfo: (state, data) => ({
      ...state,
      insuranceDetails: data,
    }),
    resetState: state => ({ ...state, refetch: true }),
    refetch: state => ({ ...state, refetch: true }),
    didRefetch: state => ({ ...state, refetch: false }),
    clearStore: _ => ({ ...settings.state }),
    scrollToView: (state, param) => ({ ...state, view: param }),
  },
};
