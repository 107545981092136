import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { compose } from '@carecloud/cloudpak';
import { store } from '../../../models';
import * as Mutations from '../../../graphql/mutations';
import { Form } from '../../rjsf';

const enableUpdateButton = complete =>
  store.dispatch.button.enableButton({
    btn: 'profileSettingsUpdate',
    complete,
  });

export const Component = React.memo(({ submit, client, schema, formData, uiSchema }) => {
  const [personalForm, setPersonalForm] = useState({ formData });
  const [fetchZipcode, setFetchZipcode] = useState(false);
  const isFormValid = !Array.isArray(personalForm.errors) || personalForm.errors.length < 1;
  const fetchDataAgainstZipcode = async () => {
    if (personalForm.formData?.address?.zipcode?.length === 5) {
      try {
        const zipcode = personalForm.formData?.address?.zipcode;
        const { data } = await client.mutate({
          mutation: Mutations.getZipcodeInfo,
          variables: {
            zipcode,
          },
        });
        const { getZipcodeInfo } = data;
        const { lookups } = getZipcodeInfo;
        const {
          0: { result },
        } = lookups;
        const dataForm = JSON.parse(JSON.stringify(personalForm));
        if (!result[0].valid) {
          dataForm.formData.address.state = '';
          dataForm.formData.address.city = '';
          enableUpdateButton(false);
        } else {
          dataForm.formData.address.state = result[0].cities[0].stateAbbreviation;
          dataForm.formData.address.city = result[0].zipcodes[0].defaultCity;
        }
        setPersonalForm(() => dataForm);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (!fetchZipcode) {
      setFetchZipcode(true);
      return;
    }
    fetchDataAgainstZipcode();
  }, [personalForm.formData?.address?.zipcode]);

  useEffect(() => {
    store.dispatch.loader.hide();
  }, []);
  const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
  useEffect(() => {
    if (isFormValid && personalForm.formData?.address?.state && personalForm.formData?.address?.city) {
      if(practiceMgmt === 'vertex'){
        enableUpdateButton(false);
      }else
      enableUpdateButton(isFormValid && personalForm.edit);
    } else {
      enableUpdateButton(false);
    }
  }, [personalForm.edit, isFormValid, personalForm.formData?.address?.state, personalForm.formData?.address?.city]);

  const handleSubmit = async () => {
    enableUpdateButton(false);

    try {
      const { data } = await client.mutate({
        mutation: Mutations.personal,
        variables: {
          input: personalForm.formData,
        },
      });

      store.dispatch.form.toggleSubmit();
      store.dispatch.toast.open(data[Object.keys(data)[0]]);
      store.dispatch.settings.refetch();
    } catch (error) {
      store.dispatch.form.toggleSubmit();
      store.dispatch.toast.error(error?.message);
      enableUpdateButton(true);
    }

    store.dispatch.loader.hide();
  };

  if (submit) {
    handleSubmit();
  }
  const formStyle = {
    pointerEvents: practiceMgmt === 'vertex' ? 'none' : 'auto',
    opacity: practiceMgmt === 'vertex' ? 0.5 : 1,
  };
  return (
    practiceMgmt === 'vertex' ? (
      <div style={formStyle}>
        <Form formData={personalForm.formData} schema={schema} uiSchema={uiSchema} onChange={setPersonalForm} />
      </div>
    ) : (
      <Form formData={personalForm.formData} schema={schema} uiSchema={uiSchema} onChange={setPersonalForm} />
    )
  );  
});

const mapStateToProps = ({ form: { submit } }) => ({ submit });

export default compose(withApollo, connect(mapStateToProps))(Component);
