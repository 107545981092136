import InputMask from 'react-input-mask';
import { isEmpty } from '@carecloud/cloudpak';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import styles from './input.module.scss';
import { Header } from '..';
import { isMobileDevice } from '../../services/utilities';

const component = React.memo(
  ({
    id,
    type,
    name,
    label,
    placeholder,
    required,
    requiredLabel,
    onChange,
    value,
    disabled,
    maskInput,
    maskChar,
    theme,
    defaultValue,
    handleValidation,
    validations,
    errors,
    autoFocus,
    supportingText,
    updatedDate,
    radios,
    errorRequiredLabel,
    rawErrors = [],
    displayOneErrorOnly = false,
    disableCopy,
    disablePaste,
  }) => {
    const onChangeWithValueChanger = e => {
      const { value } = e.target;
      const valueAfterInitialSpace = value.replace(/^\s+/, '');
      const replicaEvent = { ...e };
      if ( !value || valueAfterInitialSpace.length > 0 ) {
        let returnValue = valueAfterInitialSpace;
        const regex = /\//gi;
        returnValue = returnValue.replace(regex, '-');
       if (type !=='text' && type !=='password' && moment.locale() === 'es' && moment(returnValue, 'DD-MM-YYYY', true).isValid()) {
          const initialDate = moment(returnValue, 'DD/MM/YYYY');
          const formattedDate = initialDate.format('MM/DD/YYYY');
          replicaEvent.target.value = formattedDate;
        }
        onChange(replicaEvent);
      }
    };
    return (
      <div>
        <div id={`${id}Wrapper`} className={classNames(styles[theme], styles.formGroup)}>
          <label htmlFor={name} className={styles.label}>
            {label}
            {!value && required && <span className={styles.required}>{requiredLabel || required}</span>}
          </label>
          <div className={classNames(styles.inputWrapper, { [styles.inputWrapperWithRequired]: required })}>
            {type === 'radios' ? (
              <fieldset
                style={{
                  margin: 'unset',
                  padding: 'unset',
                  border: 'unset',
                  width: '100%',
                }}
              >
                <legend />
                <div id="radios" className={styles.radioGroup}>
                  {radios.map(radio => (
                    <div key={radio} className={styles.radio}>
                      <label htmlFor={radio}>
                        <Header {...{ id: `${radio}-header`, label: radio, type: 'H6' }} />
                      </label>
                      <input
                        type="radio"
                        value={radio}
                        id={radio}
                        key={radio}
                        name="radios"
                        onChange={_ => onChange({ target: { value: radio, id } })}
                      />
                    </div>
                  ))}
                </div>
              </fieldset>
            ) : (
              <InputMask
                autoFocus={autoFocus}
                id={id}
                mask={maskInput}
                maskChar={maskChar}
                onChange={onChangeWithValueChanger}
                style={{ flex: 1, background: isMobileDevice() && '#FFF' }}
                placeholder={placeholder}
                value={value}
                defaultValue={defaultValue}
                disabled={disabled}
                type={type}
                onBlur={validations && handleValidation}
                name={name}
                onPaste={e => {
                  if (disablePaste) {
                    e.preventDefault();
                  }
                }}
                onCopy={e => {
                  if (disableCopy) {
                    e.preventDefault();
                  }
                }}
              />
            )}
          </div>
        </div>
        {type === 'signature' && <div className={styles.signatureTime}>{updatedDate}</div>}
        {!isEmpty(rawErrors)
          ? rawErrors.map(error => (
            <div className={styles.errorMessage} key={error}>
              {errorRequiredLabel}
            </div>
            ))
          : errors &&
            !displayOneErrorOnly &&
            errors.map(error => (
              <div key={error} className={styles.errorMessage}>
                {error}
              </div>
            ))}
        {displayOneErrorOnly && (
          <div key={errors[0]} className={styles.errorMessage}>
            {errors[0]}
          </div>
        )}
        {supportingText && (
          <div className={classNames(styles.supportingText, { [styles.supportingTextGreen]: value })}>
            {supportingText}
          </div>
        )}
      </div>
    );
  },
);

export const Input = component;
