// import 'airbnb-js-shims';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import React from 'react';
import moment from 'moment';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import './font.css';
import {
  ActivityLog,
  AddAppointment,
  AppointmentCancellation,
  AppointmentCheckIn,
  AppointmentCheckOut,
  AppointmentPrepayment,
  Appointments,
  AvailableHours,
  CheckinReEntry,
  Delegate,
  DelegateProfile,
  EditPaymentPlan,
  ErrorPage,
  FormHistory,
  FormHistoryFormView,
  GetStarted,
  GuestEntryContainer,
  GuestLayoutContainer,
  GuestSuccess,
  ManageProfiles,
  MakePayment,
  Messages,
  MyHealth,
  OneTimePayment,
  Payments,
  ProfileSettings,
  Shop,
  ShopEcwid,
  ShopMakePayment,
  ShopSSO,
  Signup,
  Help,
  Surveys,
  UnsupportedBrowser,
  InactivityListener,
  LogoutCountdown,
  MessagesLink,
  IntelligentScheduler,
  Dashboard,
  vertexAvailableTime,
} from '../pages';
import ApolloError from '../modals/apolloError/apolloErrorContainer';
import { Auth } from './Auth';
import { ShortenedUrlRedirector } from '../services';
import { store } from '../../models';
import styles from './App.module.scss';
import ApolloClient from '../../graphql/ApolloClient';
import LayoutContainer from '../layout/LayoutContainer';
import TopLevelProvider from './TopLevelContext';
import Notification from '../modals/notification/NotificationContainer';
import Toast from '../modals/toast/toastContainer';
import { isValidBrowser, getMobileDevice, isMobileDevice } from '../services/utilities';
import { DEVELOPMENT, SHORT_MONTHS_ES, SHORT_MONTHS_EN } from '../../constants/Properties';
import { Utilities } from '../../constants';
import AppleMobileUniversalLink from '../services/AppleMobileUniversalLink';
import ScrollToTop from '../commonjs/ScrollToTop';

if (process.env.NODE_ENV === DEVELOPMENT) {
  whyDidYouRender(React);
}

const IN_PROD = process.env.NODE_ENV === 'production';

const MaybeAutoLogout = ({ children }) => (IN_PROD ? <InactivityListener>{children}</InactivityListener> : children);

const app = _ => {
  const language = localStorage.getItem('CC-Breeze-Web-language');
  if(language === 'es'){
    localStorage.setItem('CC-Breeze-Web-language', 'es');
  }else{
    localStorage.setItem('CC-Breeze-Web-language', 'en');
  }
  moment.locale(localStorage.getItem('CC-Breeze-Web-language') || 'en');
  moment.updateLocale(moment.locale(), { monthsShort: moment.locale() === 'es' ? SHORT_MONTHS_ES : SHORT_MONTHS_EN });
  if (!isValidBrowser()) {
    return <UnsupportedBrowser />;
  }
  // Used to handle the universal link redirect to app store
  const host = window.location.hostname;
  if (isMobileDevice() && getMobileDevice() === Utilities.APPLE && host.includes('breeze-deeplink')) {
    return <AppleMobileUniversalLink />;
  }

  return (
    <ApolloProvider client={ApolloClient}>
      <Provider store={store}>
        <TopLevelProvider>
          <div classes={styles}>
            <Router>
              <ErrorPage>
                <Switch>
                  {/* <Route exact path="/unsupported_browser" component={UnsupportedBrowser} /> */}
                  <Route exact path="/surl/:type/:hash" component={ShortenedUrlRedirector} />
                  {/* following two routes are cases where a form submission is needed before entering application */}
                  <Route
                    exact
                    path="/guest/workflow/payments"
                    render={({ location: { search = '' } }) => (
                      <Redirect
                        to={search.includes('code=') ? `/guest/guestPay${search}` : `/guest/guestPayNoCode${search}`}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/delegate/workflow/invitations"
                    render={({ location: { search } }) => <Redirect to={`/delegate/delegateVerification${search}`} />}
                  />
                  <Route
                    exact
                    path="/standalone-signup.html"
                    render={props => (
                      <Redirect from="/standalone-signup.html" to={`/guest/guestAppointment${props.location.search}`} />
                    )}
                  />
                  <Route exact path="/guest/:section" component={GuestEntryContainer} />
                  <Route
                    exact
                    path="/delegate/:section"
                    render={({ match }) => <GuestEntryContainer key={match.url} />}
                  />
                  <Route exact path="/delegateProfile" component={DelegateProfile} />
                  <Route exact path="/guest/workflow/appointments/add_appointment_success" component={GuestSuccess} />
                  <Route exact path="/guest/workflow/payments/payment_success" component={GuestSuccess} />
                  <Route path="/guest/workflow" component={GuestLayoutContainer} />
                  <Route exact path="/register_account" component={Signup} />
                  <Route exact path="/get_started" component={GetStarted} />
                  <Route exact path="/third_party/continue_check_in" component={CheckinReEntry} />
                  <Route exact path="/signup" component={Signup} />
                  {/* TO BE DEPRICATED */}
                  <Route exact path="/signup.html" component={Signup} />
                  <Route exact path="/shop/sso" component={ShopSSO} />
                  {/* TO BE DEPRICATED */}
                  <Route exact path="/shop-sso.html" component={ShopSSO} />
                  <Route exact path="/messages-link" component={MessagesLink} />
                  <Auth>
                    <MaybeAutoLogout>
                      <LayoutContainer>
                        <ScrollToTop>
                          <Switch>
                            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                            <Route exact path="/dashboard" component={Dashboard} />
                            <Route exact path="/my_health" component={MyHealth} />
                            <Route exact path="/appointments/intelligent_schedular" component={IntelligentScheduler} />
                            <Route exact path="/appointments" component={Appointments} />
                            <Route exact path="/appointments/add_appointment" component={AddAppointment} />
                            <Route
                              exact
                              path="/appointments/add_appointment/available_hours"
                              component={AvailableHours}
                            />
                            <Route
                              exact
                              path="/appointments/add_appointment/Vertexavailable_hours"
                              component={vertexAvailableTime}
                            />
                            <Route
                              exact
                              path="/appointments/add_appointment/prepayment"
                              component={AppointmentPrepayment}
                            />
                            <Route exact path="/appointments/check_out" component={AppointmentCheckOut} />
                            <Route exact path="/appointments/check_out/add_appointment" component={AddAppointment} />
                            <Route exact path="/appointments/check_out/available_hours" component={AvailableHours} />
                            <Route exact path="/appointments/check_out/survey" component={Surveys} />
                            <Route
                              exact
                              path="/appointments/:appointmentId/cancellation"
                              component={AppointmentCancellation}
                            />
                            <Route exact path="/appointments/check_in" component={AppointmentCheckIn} />
                            <Route exact path="/payments" component={Payments} />
                            <Route
                              exact
                              path="/payments/plan/:paymentPlanId/practice/:practiceId/schedule_payment"
                              component={OneTimePayment}
                            />
                            <Route
                              exact
                              path="/payments/plan/:paymentPlanId/practice/:practiceId/edit_one_time/:oneTimePaymentId"
                              component={OneTimePayment}
                            />
                            <Route
                              exact
                              path="/payments/plan/:paymentPlanId/practice/:practiceId/edit_payment_plan"
                              component={EditPaymentPlan}
                            />
                            <Route
                              exact
                              path="/payments/patient/:patientId/practice/:practiceId/make_payment"
                              component={MakePayment}
                            />
                            <Route exact path="/messages" component={Messages} />
                            <Route exact path="/messages/:message_id" component={Messages} />
                            <Route exact path="/help" component={Help} />
                            <Route exact path="/activity_log" component={ActivityLog} />
                            <Route exact path="/shop" component={Shop} />
                            <Route exact path="/shop/make_payment" component={ShopMakePayment} />
                            {/* TO BE DEPRECATED */}
                            <Route exact path="/payment" component={ShopMakePayment} />
                            <Route exact path="/form_history" component={FormHistory} />
                            <Route exact path="/form_history/:action" component={FormHistoryFormView} />
                            <Route exact path="/surveys" component={Surveys} />
                            <Route exact path="/surveys/view" component={Surveys} />
                            <Route exact path="/shop/:id" component={ShopEcwid} />
                            <Route
                              exact
                              path="/profile_settings"
                              render={() => <Redirect to="profile_settings/personal" />}
                            />
                            <Route exact path="/profile_settings/authentication/delegate" component={Delegate} />
                            <Route exact path="/manage_profiles" component={ManageProfiles} />
                            <Route exact path="/profile_settings/:section" component={ProfileSettings} />
                            <Route render={() => <Redirect to="/" />} />
                          </Switch>
                        </ScrollToTop>
                      </LayoutContainer>
                    </MaybeAutoLogout>
                    <Notification />
                    <Toast />
                  </Auth>
                </Switch>
              </ErrorPage>
              <ApolloError />
              <LogoutCountdown />
            </Router>
          </div>
        </TopLevelProvider>
      </Provider>
    </ApolloProvider>
  );
};
export const App = app;