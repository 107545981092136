/*eslint-disable comma-dangle*/

import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { store } from '../../../models';
import { ContentPage } from '..';
import { GetAvailableHours } from '../../../graphql/queries';
import { GUEST } from '../../../constants/Paths';
import { RequestAppointmentInput, makeCheckOutAppointment } from '../../../graphql/mutations';
import { parseResult } from '../../../graphql/utilities';
import { breakBackButton } from '../../services/utilities';
import { availableHoursContext as AvailableHoursContext } from '../../../contexts';

/* eslint-disable camelcase */
class AvailableHoursContainer extends Component {
  constructor(props) {
    super(props);
    // this.state = { testing: this.props.availableHours.availableHours };

    const {
      history,
      addAppointment: {
        locationId,
        visitReasonId,
        selectedProvider: { resourceId, practiceId, patientId },
      },
      guestEntry,
    } = this.props;
    store.dispatch.availableHours.resetState();
    if (!(locationId && patientId && practiceId && resourceId && visitReasonId) && !guestEntry.guestMode) {
      history.push('/appointments');
    }
  }
  componentDidMount() {
    breakBackButton();
  }

  componentDidUpdate() {
    const {
      availableHours,
      getAvailableHours,
      datePicker,
      loader: { loading },
    } = this.props;

    const queryLoading = getAvailableHours && getAvailableHours.loading;
    if (!queryLoading && loading) {
      store.dispatch.loader.activateLoader({ loading: false });
    }
    if (!isEmpty(datePicker.availableHoursSection)) {
      return this.refetchAvailableHours();
    }
    if (isEmpty(availableHours.availableHours) && !queryLoading) {
      return parseResult({
        query: getAvailableHours,
        dataField: 'getAvailableHours',
        resultHandler: store.dispatch.availableHours.fetchStart,
      });
    }
  }

  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
    store.dispatch.appointments.resetState();
  }

  continueCheckoutSurvey = ({ surveysSelection, surveys, surveysHeading }) => {
    store.dispatch.surveys.fetchStart({
      surveysSelection,
      survey: surveys,
      surveysHeading,
    });
    store.dispatch.surveys.setCheckoutSurvey();
    store.dispatch.surveys.didRefetch();
    store.dispatch.loader.persistLoader({ persist: false });
    this.props.history.push('/appointments/check_out/survey');
  };

  refetchAvailableHours = async () => {
    store.dispatch.loader.activateLoader({ loading: true });
    store.dispatch.datePicker.selectDate({
      id: 'availableHoursSection',
      value: {},
    });
    const {
      addAppointment: {
        selectedProvider: {
          resourceId: resource_ids,
          practiceId: practice_id,
          patientId: patient_id,
          specialty,
          name: providerName,
          autoSchedule = false,
          photo = String(),
        },
        locationId: location_ids,
        visitReasonId: visit_reason_id,
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        state,
        phone,
        zip,
        prePayment,
        startDate,
        endDate,
        office,
        visitType,
      },
      appointmentCheckOut: { appointment_id },
      guestEntry,
    } = this.props;
    await this.props.client.query({
      query: GetAvailableHours,
      variables: {
        input: {
          location_ids,
          ...(guestEntry.guestMode ? { patient_id: guestEntry.guestPatientId } : { patient_id }),
          practice_id,
          resource_ids,
          visit_reason_id,
          specialty,
          addressLine1,
          addressLine2,
          addressLine3,
          city,
          state,
          phone,
          zip,
          prePayment: parseFloat(prePayment),
          provider: providerName,
          start_date: startDate,
          end_date: endDate || startDate,
          office,
          visitType,
          checkout: !!appointment_id,
          autoSchedule,
          photo,
          isGuest: this.props.location.pathname.includes(GUEST),
        },
      },
    });
    return store.dispatch.availableHours.resetState();
  };
  requestAppointment = async _ => {
    const {
      addAppointment: {
        selectedProvider: { practiceMgmt: practice_mgmt, practiceId: practice_id, providerId: provider_id },
        visitReason,
      },
      availableHours: { appointment },
      dateTimeSelect: {
        availableHoursSection: {
          value: { start_time, end_time },
        },
      },
      guestEntry,
    } = store.getState();
    const {
      match: { url },
    } = this.props;
    const requestAppointment = {
      ...appointment,
      start_time,
      end_time,
      provider_id: typeof provider_id === 'string' ? parseInt(provider_id, 10) : provider_id,
      ...(visitReason && { chief_complaint: visitReason }),
    };
    const input = {
      practice_id,
      practice_mgmt,
      appointment: requestAppointment,
      isGuest: this.props.location.pathname.includes(GUEST),
    };
    try {
      if (url.includes('check_out')) {
        const {
          appointmentCheckOut: { appointment_id, patient_id, practice_id, practice_mgmt, location_guid },
        } = this.props;
        const checkout = {
          appointment: requestAppointment,
          checkoutIds: {
            appointment_id,
            patient_id,
            practice_id,
            practice_mgmt,
            location_guid,
          },
        };
        store.dispatch.loader.persistLoader({ persist: true });
        const {
          data: { makeCheckoutAppointment },
        } = await this.props.makeCheckOutAppointment({
          variables: { input: checkout },
        });
        if (makeCheckoutAppointment?.submissionResult?.success) {
          store.dispatch.modal.toggleOpen('requestApptSuccessModal');
          store.dispatch.loader.persistLoader({ persist: false });
        } else if (makeCheckoutAppointment?.redirectSurvey) {
          const { surveysSelection, surveys, surveysHeading } = makeCheckoutAppointment;
          this.continueCheckoutSurvey({
            surveysSelection,
            surveys,
            surveysHeading,
          });
        } else {
          store.dispatch.appointmentCheckOut.fetchCheckOutPayment({
            appointmentCheckOutPayment: makeCheckoutAppointment?.appointmentCheckOutScreen,
          });
          store.dispatch.loader.persistLoader({ persist: false });
          this.props.history.push('/appointments/check_out');
        }
      } else {
        store.dispatch.loader.persistLoader({ persist: true });
        const data = await this.props.requestAppointmentInput({
          variables: { input },
        });
        store.dispatch.addAppointment.clearStore();
        store.dispatch.intelligentScheduler.clearAll();
        store.dispatch.addAppointment.resetSelectedOptions();
        store.dispatch.loader.persistLoader({ persist: false });
        if (data?.data?.getRequestAppointment?.status === 200) {
          if (guestEntry.guestMode) {
            this.transitionGuestSuccess(data);
          } else {
            if (!data.data.getRequestAppointment.move_patient_pre_registration) {
              store.dispatch.modal.toggleOpen('requestApptSuccessModal');
              return;
            }

            const ph = data.data.getRequestAppointment?.location?.phones.filter(e => e.is_primary)[0];
            const checkin = {
              providerName: data.data.getRequestAppointment.provider.name,
              appointmentStartTime: data.data.getRequestAppointment.appointmentStartTime,
              addressLine1: data.data.getRequestAppointment.location.address.line1,
              addressLine2: data.data.getRequestAppointment.location.address.line2,
              addressLine3: data.data.getRequestAppointment.location.address.line3,
              city: data.data.getRequestAppointment.location.address.city,
              state: data.data.getRequestAppointment.location.address.state_name,
              zipCode: data.data.getRequestAppointment.location.address.zip_code,
              phoneNumber: ph?.phone_number,
              locationName: data.data.getRequestAppointment.location.name,
              appointment_id: data.data.getRequestAppointment.appointment_id,
              patient_id: data.data.getRequestAppointment.patient_id,
              practice_id: data.data.getRequestAppointment.practice_id,
              practice_mgmt: data.data.getRequestAppointment.practice_mgmt,
              provider_id:
                data.data.getRequestAppointment.provider?.guid || data.data.getRequestAppointment.provider?.id,
              location_id:
                data.data.getRequestAppointment.location?.guid || data.data.getRequestAppointment.location?.id,
            };
            store.dispatch.appointmentCheckIn.storeAppointmentData(checkin);
            store.dispatch.modal.toggleOpen('goToPreRegisteration');
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  checkIn = _ => {};

  transitionGuestSuccess = appointmentInfo => {
    store.dispatch.guest.setGuestSuccessData({
      ...appointmentInfo,
      workflow: 'appointments',
    });
    this.props.history.push('/guest/workflow/appointments/add_appointment_success');
  };

  render() {
    const {
      availableHours: { availableHours },
    } = this.props;
    return (
      availableHours && (
        <AvailableHoursContext.Provider value={{ requestAppointment: this.requestAppointment, checkIn: this.checkIn }}>
          <ContentPage schemas={[availableHours]} />

          {this.state?.testing && <ContentPage schemas={[this.state.testing]} />}
        </AvailableHoursContext.Provider>
      )
    );
  }
}
const mapStateToProps = ({
  availableHours,
  loader,
  addAppointment,
  datePicker,
  dateTimeSelect,
  appointmentCheckOut,
  guestEntry,
  guest,
}) => ({
  availableHours,
  loader,
  addAppointment,
  datePicker,
  dateTimeSelect,
  appointmentCheckOut,
  guestEntry,
  guest,
});

const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps),
  graphql(makeCheckOutAppointment, { name: 'makeCheckOutAppointment' }),
  graphql(RequestAppointmentInput, { name: 'requestAppointmentInput' }),
  graphql(GetAvailableHours, {
    name: 'getAvailableHours',
    skip: ({
      addAppointment: {
        selectedProvider: { resourceId: resource_ids, practiceId: practice_id, patientId: patient_id },
        locationId: location_ids,
        visitReasonId: visit_reason_id,
      },
      availableHours,
      guestEntry,
    }) =>
      (!(location_ids && patient_id && practice_id && resource_ids && visit_reason_id) && !guestEntry.guestMode) ||
      !isEmpty(availableHours.availableHours),
    options: ({
      addAppointment: {
        selectedProvider: {
          resourceId: resource_ids,
          practiceId: practice_id,
          specialty,
          name: providerName,
          practiceMgmt: practice_mgmt,
          autoSchedule,
          photo,
          patientId: patient_id,
        },
        locationId: location_ids,
        visitReasonId: visit_reason_id,
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        state,
        phone,
        zip,
        prePayment,
        startDate,
        endDate,
        office,
        visitType,
        fromReschedule,
      },
      appointmentCheckOut: { appointment_id },
      guestEntry,
    }) => ({
      variables: {
        input: {
          fromReschedule,
          location_ids,
          ...(guestEntry.guestMode ? { patient_id: guestEntry.guestPatientId } : { patient_id }),
          practice_id,
          practice_mgmt,
          resource_ids,
          visit_reason_id,
          specialty,
          addressLine1,
          addressLine2,
          addressLine3,
          city,
          state,
          phone,
          zip,
          prePayment: parseFloat(prePayment),
          provider: providerName,
          start_date: startDate,
          end_date: endDate || startDate,
          office,
          visitType,
          checkout: !!appointment_id,
          autoSchedule,
          photo,
          isGuest: guestEntry.guestMode,
        },
      },
    }),
  }),
);
export default enhancer(AvailableHoursContainer);
